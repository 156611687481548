import utils from './../utils';

class accountHolder {
	static async fetchDetails(intakeId) {

		const response = await utils.api.get(`/patients/account-holder-information/${intakeId}`);

                console.log(response)
                console.log("response")

		return response.data;
	}
}

export default accountHolder;
