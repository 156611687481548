import axios from 'axios';
import lib from "!/library-web/lib";
// import utils from './index';

class api {

    static getHeaders() {
        let token = lib.store.get('token');
        let enterprise_id = lib.store.get('enterprise_id');
        let headers = {
            'Content-Type': 'application/json',
            'Token': token
        };
        if (enterprise_id) {
            headers['Partner'] = enterprise_id;
        }
        return headers;
    }

    static getFileHeaders() {
        let token = lib.store.get('token');
        let enterprise_id = lib.store.get('enterprise_id');
        let headers = {
            'Content-Type': 'multipart/form-data',
            'Token': token
        };
        if (enterprise_id) {
            headers['Partner'] = enterprise_id;
        }
        return headers;
    }

    static async post(path, data) {
        let headers = api.getHeaders();
        let result = await axios.post(path, data, {
            headers: headers
        });
        return result.data;
    }

    static async postDocument(path, data) {
        let headers = api.getFileHeaders();
        let result = await axios.post(path, data, {
            headers: headers
        });
        return result.data;
    }

    static async get(path) {
        let headers = api.getHeaders();
        let result = await axios.get(path, {
            headers: headers
        });
        return result.data;
    }

    static async put(path, data) {
        const headers = api.getHeaders();
        axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
        let result = await axios.put(path, data, {
			headers: headers
        });
        return result.data;
    }

    static async puts3(path, data) {
		axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
		let result = await axios.put(path, data, {});
		
		return result.data;
	}
    
    static async putDocument(path, data) {
        let headers = api.getFileHeaders();
        let result = await axios.put(path, data, {
            headers: headers
        });
        return result.data;
    }

    static async putDocument(path, data) {
        let headers = api.getFileHeaders();
        let result = await axios.put(path, data, {
            headers: headers
        });
        return result.data;
    }

    static configure(router, config) {
        axios.defaults.baseURL = `https://${config.patients_api.host}`;

        axios.interceptors.request.use(config => {
            lib.progress.start();
            return config;
        });

        axios.interceptors.response.use((response) => {
            lib.progress.stop();
            return response;
        }, (err) => {
            lib.progress.stop();
            if (err) {
                const originalRequest = err.config;
                if (err.response.status === 401 && !originalRequest._retry) {
                    originalRequest._retry = true;
                    return router.push('/login');
                } else {
                    console.log(err.response);
                    lib.toast.showError('Request failed.');
                }
            }
        });
    }
}

export default api;